<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("BOOKINGS.BOOKING_LIST") }}</h3>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            class="search"
            type="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <loading-panel v-show="loading"></loading-panel>
        <div
          class="page-wrapper-table-body-inner"
          v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }"
        >
          <el-table
            :data="bookings"
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            @sort-change="sortChange"
          >
            <!-- region date d'arrivee et de depart -->
            <!-- start_at -->
            <el-table-column
              :label="$t('BOOKINGS.ARRIVING_AT')"
              prop="end_at"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $formatDate(row.start_at, "LL") }}
              </template>
            </el-table-column>

            <!-- end_at -->
            <el-table-column
              :label="$t('BOOKINGS.LEAVING_AT')"
              prop="end_at"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $formatDate(row.end_at, "LL") }}
              </template>
            </el-table-column>
            <!-- endregion -->

            <!-- code -->
            <el-table-column
              :label="$t('BOOKINGS.BOOKING_CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            />

            <!-- emplacement -->
            <el-table-column
              :label="$t('COMMON.LOCATION')"
              prop="spot.name"
              sortable="custom"
              min-width="240"
            />

            <!-- duree du sejour -->
            <el-table-column
              :label="$t('BOOKINGS.DURATION')"
              prop="duration"
              min-width="120"
            />

            <!-- creee l -->
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column fixed="right" min-width="140">
              <div slot-scope="{ row }" class="table-actions">
                <a
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_BOOKINGS)"
                  type="text"
                  @click="goToBookingDetailsPage(row.id)"
                  class="table-action button"
                  data-toggle="tooltip"
                >
                  {{ $t("COMMON.DETAILS") }}
                </a>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option,
  Button,
} from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { BasePagination } from "@/components";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "customer-view-history",

  props: {
    customerId: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    LoadingPanel,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      bookings: [],
      loading: false,
      dateRange: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {},

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            customer_id: this.customerId,
            ...(this.query ? { search: this.query } : {}),
          },
          include: "spot",
        };
        await this.$store.dispatch("bookings/list", params);
        this.bookings = this.$store.getters["bookings/list"];
        this.total = this.$store.getters["bookings/listTotal"];
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    goToBookingDetailsPage(bookingId) {
      let routingParams = {
        name: "List Bookings",
        query: { id: bookingId, action: QUERY_ACTIONS_VIEW },
      };

      let href = this.$router.resolve(routingParams).href;
      history.pushState({}, null, href);

      this.$router.push(routingParams);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },

  mounted() {},

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
};
</script>
